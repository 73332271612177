<button class="menuButton" (click)="menuOpen = !menuOpen">
  <div></div>
  <div></div>
  <div></div>
</button>
<div class="listBlock" [ngClass]="{'opened': menuOpen}">
  <div class="content">
    <button [routerLink]="'/general'" [routerLinkActive]="'active'">{{'menu.info'|translate}}</button>
    <button [routerLink]="'/room-list'" [routerLinkActive]="'active'">{{'menu.rooms'|translate}}</button>
    <div class="extendBlockShort">
      <button [routerLink]="'/room'" [routerLinkActive]="'active'">{{'menu.createRoom'|translate}}</button>
    </div>
    <button [routerLink]="'/photo'" [routerLinkActive]="'active'">{{'menu.photo'|translate}}</button>
    <button [routerLink]="'/tariff-list'">{{'menu.tariffs'|translate}}</button>
    <div class="extendBlockLong">
      <button [routerLink]="'/tariff-list'" [routerLinkActive]="'active'">{{'menu.tariffsView'|translate}}</button>
      <button [routerLink]="'/tariff'" [routerLinkActive]="'active'">{{'menu.tariffCreate'|translate}}</button>
    </div>
    <button [routerLink]="'/discount'" [routerLinkActive]="'active'">{{'menu.discounts'|translate}}</button>
    <button [routerLink]="'/booking'" [routerLinkActive]="'active'">{{'menu.bookings'|translate}}</button>
  </div>
  <div class="sticky-bottom text-white mb-3 mx-2">
    <div class="h6 text-white">Підтримка готельєрів:</div>
    <a href="mailto:tak@yavdoma.com">tak@yavdoma.com</a>
    <div>+38067 1101181</div>
    <div>З 10.00 до 18.00 робочі дні</div>
  </div>
</div>
